@font-face {
  font-family: "Barlow-Regular";
  src: url("../../public/assets/fonts/Barlow-Regular.ttf");
}

@font-face {
  font-family: "Barlow-Medium";
  src: url("../../public/assets/fonts/Barlow-Medium.ttf");
}

@font-face {
  font-family: "Barlow-SemiBold";
  src: url("../../public/assets/fonts/Barlow-SemiBold.ttf");
}

@font-face {
  font-family: "Barlow-Bold";
  src: url("../../public/assets/fonts/Barlow-Bold.ttf");
}

@font-face {
  font-family: "Barlow-ExtraBold";
  src: url("../../public/assets/fonts/Barlow-ExtraBold.ttf");
}
